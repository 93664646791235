import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import "./App.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./i18n";
import { LANGUAGE } from "./i18n/constant";

// import { createBrowserHistory } from "history"

// const browserHistory = createBrowserHistory()
i18n.use(initReactI18next).init({
  resources: translation,
  lng: localStorage?.getItem('lng') || LANGUAGE.JP,
  fallbackLng: LANGUAGE.EN,
});
export default class App extends Component {
  render() {
    return (
      <BrowserRouter
      // location={browserHistory.location}
      // navigator={browserHistory}
      >
        <Router />
      </BrowserRouter>
    );
  }
}
