import React from "react";
import { useTranslation } from "react-i18next";
import Styled from "./styled";
const Sidebar = ({ activeId, setOpenSidebar }) => {
  const { t } = useTranslation();
  const ids = [
    t("menu.dashboard"),
    t("menu.business_content"),
    t("menu.company_profile"),
    t("menu.news"),
    t("menu.chairman"),
    t("menu.inquiry"),
  ];
  return (
    <Styled
      data-aos="fade-right"
      data-aos-duration="300"
      style={{
        zIndex: "9999",
        background: "rgba(1,1,1,0.8)",
        width: "calc(100%)",
      }}
      className="bg-white lg:hidden fixed h-screen"
    >
      <img
        onClick={() => setOpenSidebar(false)}
        className="w-[30px] ml-[20px] mt-[35px]"
        src="close.svg"
        alt="close_ic"
      />
      <div className="">
        {ids.map((menuItem, index) => (
          <div
            data-aos="fade-up"
            data-aos-duration={`${index + 3}00`}
            className="mt-6"
          >
            <a
              onClick={() => setOpenSidebar(false)}
              className={`text-xl font-bold ${
                menuItem === activeId && "active"
              }`}
              href={`#${menuItem}`}
            >
              {menuItem}
            </a>
          </div>
        ))}
      </div>
    </Styled>
  );
};
export default Sidebar;
