import { Col, Image, Row } from "antd";
import Styled from "./styled";
import { useTranslation } from "react-i18next";

const CardItem = ({ title, description, description1, image }) => {
  function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }
  return (
    <div data-aos="zoom-in-up" className="rounded-[20px] bg-[#f6f6f6]">
      {description1 ? (
        <a
          href="https://listen-web.com/story/ishikawa-yudai/"
          target="blank"
          onClick={() => {
            scrollToBottom();
          }}
          className="hover:!text-[#000000]"
        >
          <div className="pt-6 pb-6 pl-[16px] pr-[16px]">
            <p className="leading-[150%] break-all mb-4 text-[24px] text-left text-[#000000] font-bold	">
              {title}
            </p>
            <p className="text-[14px] leading-[19px] text-left font-light">
              {description}
            </p>
            <p className="text-[14px] pt-2 leading-[19px] text-left font-light">
              {description1}
            </p>
          </div>
        </a>
      ) : (
        <>
          <div className="pt-6 pb-6 pl-[16px] pr-[16px]">
            <p className="leading-[150%] break-all mb-4 text-[24px] text-left text-[#000000] font-bold	">
              {title}
            </p>
            <p className="text-[14px] leading-[19px] text-left font-light">
              {description}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
const News = () => {
  const { t } = useTranslation();
  return (
    <>
      <Styled>
        <Row justify="center" className="pb-[100px] px-4 lg:px[50px]">
          <Col
            data-aos="fade-right"
            span={24}
            className="text-left pt-[80px] pb-[64px]"
          >
            <h1 className="text-5xl font-semibold pb-4">{t("news.title")}</h1>
          </Col>
          <Col span={24}>
            <div className="item-grid grid grid-cols-3">
              <CardItem
                title={t("service.one.title")}
                description={t("service.one.description")}
                image="service-img1-s2.png"
              />
              <CardItem
                title={t("service.two.title")}
                description={t("service.two.description")}
                image="service-img2-s2.png"
              />
              <CardItem
                title={t("service.three.title")}
                description={t("service.three.description")}
                image="service-img3-s2.png"
              />
              <CardItem
                title={t("service.four.title")}
                description={t("service.four.description")}
                image="service-img4-s2.png"
              />
             
              <CardItem
                title={t("service.five.title")}
                description={t("service.five.description")}
                description1={t("service.five.description1")}
                image="main_pic-3.webp"
              />
            </div>
          </Col>
        </Row>
      </Styled>
    </>
  );
};
export default News;
