import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";

import "japanese-fonts-css";
import Styled from "./styled";
import ContentPage from "../ContentPage/MainContent/ContentPage";

const MainPage = () => {
  const contentStyle = {
    width: "100%",
    textAlign: "center",
    color: "#fff",
  };

  return (
    <Styled>
        <Layout>
          <Content style={contentStyle}>
            <ContentPage />
          </Content>
        </Layout>
    </Styled>
  );
};
export default MainPage;
