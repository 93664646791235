import { useTranslation } from "react-i18next";

const CardItem = ({ link, image, position, name, experience }) => {
  return (
    <div
      data-aos="flip-right"
      className="rounded-[20px] mt-16 lg:mt-0 flex flex-col justify-between px-[24px] bg-[#242424]"
    >
      <div>
        <img className="rounded-t-lg w-[460px] h-[458px] mb-8" alt="" src={image} />
        {experience?.map((item) => (
          <div className="flex max-w-[460px]">
            <span className="text-[14px] min-w-[50px] text-left font-light">
              {item?.year}
            </span>
            <div>
              {item?.title?.map((title) => (
                <p className="text-[14px] text-left font-light">{title}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div>
        <p className="pt-[60px] text-[14px] leading-[19px] pb-[3px] text-left font-light">
          {position}
        </p>
        <p className="text-[24px] text-left font-medium pb-[20px]"> {name}</p>
        <a href={link}>
          <div className="text-left flex">
            <img src="_Facebook.svg" alt="_Facebook" />
            <p className="ml-[11px]">Facebook</p>
          </div>
        </a>
      </div>
    </div>
  );
};

const ContentProfile = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pb-[90px] xl:px-[100px] bg-[#242424]">
        <div className="flex items-center justify-center">
          <div className="flex flex-wrap justify-center pt-[100px]">
            <CardItem
              experience={t("profile.one", { returnObjects: true })}
              image="human_profile-modified.png"
              position={t('profile.ceo')}
              name={t('profile.ceo_name')}
              link="https://www.facebook.com/yudai.ish"
            />
            <CardItem
              experience={t('profile.two', { returnObjects: true })}
              image="profile-img2-s4.png"
              position={t('profile.president')}
              name={t('profile.president_name')}
              link="https://www.facebook.com/profile.php?id=100087587786644"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ContentProfile;
