import { Col, Image, Row } from "antd";
import Styled from "./styled";
import { useTranslation } from "react-i18next";

const CardItem = ({ title, description, image }) => {
  return (
    <div data-aos="zoom-in-up" className="rounded-[20px] bg-[#f6f6f6]">
      <Image preview={false} className="rounded-t-lg" src={image} />
      <div className="pt-6 pb-6 pl-[16px] pr-[16px]">
        <p className="leading-[150%] break-all mb-4 text-[24px] text-left text-[#000000] font-bold	">
          {title}
        </p>
        <p className="text-[14px] leading-[19px] text-left font-light">
          {description}
        </p>
      </div>
    </div>
  );
};
const ContentService = () => {
  const { t } = useTranslation();
  return (
    <>
      <Styled>
        <Row justify="center" className="pb-[100px] px-4 lg:px[50px]">
          <Col
            data-aos="fade-right"
            span={24}
            className="text-left pt-[80px] pb-[64px]"
          >
            <h1 className="text-5xl font-semibold pb-4">
              {t("service.title")}
            </h1>
            <p className="text-xl font-light">{t("service.description")}</p>
          </Col>
          <Col span={24}>
            <div className="item-grid grid grid-cols-4">
              <CardItem
                title={t('service.one.title')}
                description={t('service.one.description')}
                image="service-img1-s2.png"
              />
              <CardItem
                title={t('service.two.title')}
                description={t('service.two.description')}
                image="service-img2-s2.png"
              />
              <CardItem
                title={t('service.three.title')}
                description={t('service.three.description')}
                image="service-img3-s2.png"
              />
              <CardItem
                title={t('service.four.title')}
                description={t('service.four.description')}
                image="service-img4-s2.png"
              />
            </div>
          </Col>
        </Row>
      </Styled>
    </>
  );
};
export default ContentService;
