import { Image } from "antd";
import { useTranslation } from "react-i18next";

const FooterPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col justify-center h-[367px] px-4 lg:px-[40px] bg-[#035F57]">
        <div className="flex flex-wrap justify-between">
          <div data-aos="fade-up" className="text-left mb-6 md:mb-0 !text-[16px]">
            <Image preview={false} src="logo-cocert.svg" />
            {t("footer.info", { returnObjects: true }).map((info) => (
              <p style={{ color: "#DDDDDD" }} className="!text-[16px]">
                {info}
              </p>
            ))}
          </div>
          <div data-aos="fade-up" className="text-left flex flex-col">
            <p className="!text-[24px] font-bold">{t('footer.contact')}</p>
            <div className="!text-[16px] flex pt-[10px]">
              <Image preview={false} src="mail.svg" className="pr-[15px]" />
              <a
                rel="noreferrer"
                target="_blank"
                href="mailto:info@co-cert.com"
                style={{ color: "#DDDDDD" }}
              >
                info@co-cert.com
              </a>
            </div>
            <div className="!text-[16px] flex pt-[10px]">
              <Image preview={false} src="location.svg" className="pr-[15px]" />
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/4-ch%C5%8Dme-28-10+Daita,+Setagaya+City,+Tokyo+155-0033,+Nh%E1%BA%ADt+B%E1%BA%A3n/@35.6603311,139.6542426,17z/data=!3m1!4b1!4m6!3m5!1s0x6018f37335062d15:0xc50eddd462f6c11d!8m2!3d35.6603268!4d139.6568175!16s%2Fg%2F11clqkmkt0?hl=vi-VN"
                style={{ color: "#DDDDDD" }}
              >
                {t('footer.address')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FooterPage;
