import React from "react";
import { Row, Col, Carousel } from "antd";
import Styled, { Container } from "./styled";
import { useTranslation } from "react-i18next";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "15px",
        position: "absolute",
        right: "104.5px",
        zIndex: 9999,
        transform: "translate(0, 50%)",
      }}
      onClick={onClick}
    >
      <img style={{ maxWidth: 65 }} src="circle_right.svg" alt="circle_left" />
    </div>
  );
};

const CaroselItem = ({ image }) => {
  const { t } = useTranslation();
  return (
    <Styled>
      <div
        className="min-h-screen bg-blue-100 p-4 lg:p-16 xl:p-32 text-center bg-cover flex justify-center"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div
          data-aos="zoom-in"
          className="flex flex-col justify-center w-[1000px]"
        >
          <h1 className="text-[47px] md:text-[60px] lg:text-[100px] leading-[100%] font-bold pb-[16px] bg-clip-text text-transparent bg-gradient-to-r text-white">
            {t("company.name")}
          </h1>
          <div
            className="font-normal font-[Zen Kaku Gothic Antique]"
            style={{ color: "rgba(255, 255, 255, 0.8)" }}
          >
            <p className="text-[#FFFFFF] text-md md:text-lg xl:text-xl leading-[150%] font-normal">
              {t("company.description_1st")}
              <br />
              {t("company.description_2nd")}
            </p>
          </div>
        </div>
      </div>
    </Styled>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "15px",
        position: "absolute",
        left: "57.5px",
        transform: "translate(0, 50%)",
        zIndex: 9999,
      }}
      onClick={onClick}
    >
      <img style={{ maxWidth: 65 }} src="circle_left.svg" alt="circle_left" />
    </div>
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const ContentCompany = () => {
  return (
    <Container>
      <Row className="content-company" justify="center">
        <Col span={24}>
          <Carousel arrows>
            <CaroselItem image="company-s1.png" />
            <CaroselItem image="company-s1.png" />
            <CaroselItem image="company-s1.png" />
            <CaroselItem image="company-s1.png" />
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
};

export default ContentCompany;
