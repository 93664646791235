import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import MainPage from "./layouts/MainPage/MainPage";

const Router = () => {
  return (
    <Suspense>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
};

export default Router;
