import styled from "styled-components";

export default styled.div`
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open::after {
    border-bottom-color: #000;
    border-bottom-width: thick;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open:hover {
    border-bottom-color: white;
    border-bottom-width: thick;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open:focus {
    border-bottom-color: #000;
    border-bottom-width: thick;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open:active {
    border-bottom-color: #000;
    border-bottom-width: thick;
  }
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after {
    margin-top: 5px;
    border-bottom-color: #000;
    border-bottom-width: thick;
  }
  a:hover {
    color: #fff;
  }
  .ant-layout {
    overflow-x: hidden;
  }
  .ant-anchor-wrapper-horizontal .ant-anchor {
    background: #f2f2f2;
    height: 57px;
    align-items: center;
  }
  .item-grid {
    grid-area:content;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap:20px
  }
`;
