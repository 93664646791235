const translation = {
  jp: {
    translation: {
      menu: {
        dashboard: "ホーム",
        business_content: "事業内容",
        company_profile: "会社概要",
        news: "ニュース",
        chairman: "日本語",
        inquiry: "お問い合わせ",
      },
      //section 1
      company: {
        name: "株式会社コーチェルト",
        description_1st: "私たちは、オーナー企業および富裕層のお客様向けに、",
        description_2nd:
          "資産形成をはじめとする幅広い金融サービスを提供しています。",
      },
      //section 2
      company_profile: {
        title: "会社概要",
        description:
          "私たちCoCertは、お客様の資産形成を支援し、財務、相続、M&A、海外保険アドバイザリーなどの専門的なサービスを提供しています。私たちのチームは、金融や不動産、ビジネスの専門知識を持ち、お客様のニーズに合った最適なソリューションを提供することをお約束します。",
      },
      //section 3
      service: {
        title: "提供サービス",
        description:
          "私たちはお客様の信頼に応えるために、常に最高水準のサービスを提供します。",
        one: {
          title: "オーナー企業／富裕層/ハイクラス向け資産形成",
          description:
            "お客様の資産を最大限に活用し、持続可能な財務成長をサポートします。",
        },
        two: {
          title: "財務/相続/M&A/海外保険アドバイザリー",
          description:
            "金融や不動産、ビジネスの専門知識を持ち、お客様のニーズに合った最適なソリューションを提供することをお約束します。",
        },
        three: {
          title: "国内、海外不動産／ 補助金／資金調達 等",
          description:
            "国内外不動産に関する豊富な知識と経験を持ち、最適な不動産投資戦略、補助金の活用や資金調達のサポートなど、お客様のビジネスの成長を支援する幅広いサービスも提供します。",
        },
        four: {
          title: "その他各種アドバイザリー",
          description:
            "専門的なアドバイスを通じて、お客様の財務戦略を最適化し、長期的な資産の保全と増加を支援しています。",
        },
        five: {
          title: "代表取締役　石川雄太インタビュー",
          description:
            "資本の力で富を生み出し、分配も担う。「地球上のすべての子どもたちが希望を持てる社会」を創出し、世界平和の実現へ。",
          description1:
            "経済価値と社会価値の創出を両立するファイナンスのプロとしてのインタビュー記事です。",
        },
      },
      news: {
        title: "News",
      },
      //section 4
      profile: {
        one: [
          {
            year: "1988年",
            title: ["静岡県湖西市生まれ（愛知県豊橋市育ち)"],
          },
          {
            year: "2011年",
            title: ["明治大学政治経済学部 卒業"],
          },
          {
            year: "2011年",
            title: ["税理士法人 入社"],
          },
          {
            year: "2013年",
            title: ["アクセンチュア(株)Client Financial Management 入社"],
          },
          {
            year: "2018年",
            title: ["(株) 日本 M&A センター 社長室 経営企画室 入社"],
          },
          {
            year: "2018年",
            title: ["コモングッド（株）取締役 就任"],
          },
          {
            year: "2019年",
            title: ["キャリアプ（株）創立 CEO就任"],
          },
          {
            year: "2019年",
            title: ["(株) ドリームインスティテュート 戦略アドバイザー 就任"],
          },
          {
            year: "2021年",
            title: ["(株) Maxell Consulting 創立 CEO就任", "(株）&Lux 創立"],
          },
          {
            year: "2022年",
            title: [
              "(株) Tax Strategies Japan 創立",
              "(株) Mark A＆B 創立",
              "(株）日本能率未来総研 創立",
              "Future Directions税理士事務所 創立",
            ],
          },
          {
            year: "2023年",
            title: [
              "(株) コーチェルト 代表取締役 就任",
              "(株) CodeCrush 創立",
              "一般社団法人 ミライギフト 創立 理事就任",
              "その他、30を超える事業立ち上げに関わり、エンジェル投資、ドネーション活動、世界のスタディロスをなくし平和を実現する活動に従事。経営者の育成にも力を入れる。",
            ],
          },
        ],
        two: [
          {
            year: "1980年",
            title: ["株式会社三菱銀行(現三菱UFJ銀行)　入社"],
          },
          {
            year: "2006年",
            title: ["株式会社三菱東京UFJ銀行(現三菱UFJ銀行)　執行役員　就任"],
          },
          {
            year: "2010年",
            title: ["株式会社三菱東京UFJ銀行　常務執行役員　就任"],
          },
          {
            year: "2012年",
            title: ["株式会社三菱東京UFJ銀行　代表取締役常務　就任"],
          },
          {
            year: "2014年",
            title: ["株式会社三菱東京UFJ銀行　代表取締役専務　就任"],
          },
          {
            year: "2015年",
            title: ["東京急行電鉄株式会社常勤監査役　就任"],
          },
          {
            year: "2020年",
            title: [
              "株式会社バンカーズ・ホールディング　代表取締役会長　就任 (現在相談役)",
            ],
          },
          {
            year: "2023年",
            title: [
              "(株）コーチェルト 会長 就任",
              "藤田医科大学客員教授",
              "一般社団法人メタバース推進協議会監事",
              "公益財団法人日本舞台芸術振興会理事",
              "シンワ ワイズHD社外取締役",
              "他多数のベンチャーに出資、役員・顧問に就任。ベンチャーの育成支援をライフワークとする。",
            ],
          },
        ],
        ceo: "代表取締役",
        ceo_name: "石川 雄大",
        president: "会長",
        president_name: "長田 忠千代",
      },
      //section 5
      footer: {
        info: [
          "・オーナー企業／富裕層/ハイクラス向け資産形成",
          "・財務/相続/M&A/海外保険アドバイザリー",
          "・国内、海外不動産／ 補助金／資金調達 等",
          "・その他各種アドバイザリー",
        ],
        contact: "Contact",
        address: "4-28-10 Daita, Setagaya-ku, Tokyo",
      },
    },
  },
  en: {
    translation: {
      menu: {
        dashboard: "Home",
        business_content: "Business details",
        company_profile: "Company overview",
        news: "News",
        chairman: "Chairman's greeting",
        inquiry: "Contact",
      },
      //section 1
      company: {
        name: "Co-Cert Co., Ltd.",
        description_1st:
          "We provide a wide range of financial services, including asset-building,",
        description_2nd:
          "for owner-operator companies and high-net-worth clients.",
      },
      //section 2
      company_profile: {
        title: "Company overview",
        description:
          "We at CoCert help our clients build their asset and provide professional services in finance, inheritance, M&A and international insurance advisory. Our team has expertise in finance, real estate and business and is committed to providing the best solutions for client's needs.",
      },
      //section 3
      service: {
        title: "Providing service",
        description:
          "We are committed to providing the highest standard of service at all times to prove worthy of client's trust",
        one: {
          title:
            "Asset-building for Owner-operator companies/High-net-worth/ High class",
          description:
            "We help our clients maximize their assets and support sustainable financial growth.",
        },
        two: {
          title: "Finance/Inheritance/M&A/International Insurance Advisory",
          description:
            "With our expertise in finance, real estate and business, we are committed to providing the best solutions to meet client's needs.",
        },
        three: {
          title:
            "Domestic and international real estate / subsidies / fundraising, etc.",
          description:
            "With our extensive knowledge and experience in domestic and international real estate, we also provide a wide range of services to support the growth of our clients' businesses, including optimal real estate investment strategies, support in utilizing subsidies and fundraising.",
        },
        four: {
          title: "Other advisory services",
          description:
            "We help our clients optimize their financial strategies and preserve as well as increase their assets over the long term by professional advice.",
        },
        five: {
          title: "Interview with Representative Director Yuuta Ishikawa",
          description:
            `Utilizing the power of capital to generate wealth and also take on its distribution, our goal is to create "a society where all children on Earth can hold hope," thereby contributing to the realization of world peace.`,
            description1: `This interview article presents insights from a professional in finance who balances the creation of both economic and social value`
        },
      },
      //section 4
      news: {
        title: "News",
      },
      profile: {
        one: [
          {
            year: "In 1988",
            title: [
              "Born in Kosai City, Shizuoka Prefecture (grew up in Toyohashi City, Aichi Prefecture)",
            ],
          },
          {
            year: "In 2011",
            title: [
              "Graduated from Faculty of Political Economy of Meiji University",
            ],
          },
          {
            year: "In 2011",
            title: ["Joined Certified Public Tax Accountant"],
          },
          {
            year: "In 2013",
            title: ["Joined Accenture, Inc.  Client Financial Management "],
          },
          {
            year: "In 2018",
            title: [
              "Joined as President's Office of Corporate Planning Office - Japan M&A Center, Inc.",
            ],
          },
          {
            year: "In 2018",
            title: ["Appointed Director of Common Good, Inc."],
          },
          {
            year: "In 2019",
            title: ["Founder and CEO of CareerP Inc."],
          },
          {
            year: "In 2019",
            title: ["Appointed Strategic Advisor, Dream Institute Inc."],
          },
          {
            year: "In 2021",
            title: [
              "Founder and CEO of Maxell Consulting Inc.",
              "Founded &Lux Inc.",
            ],
          },
          {
            year: "In 2022",
            title: [
              "Founded Tax Strategies Japan Inc.",
              "Founded Mark A & B Inc.",
              "Founded JMA Research Institute, Inc.",
              "Founded Tax Law Office - Future Directions",
            ],
          },
          {
            year: "In 2023",
            title: [
              "Appointed Representative Director of Coacheltte, Inc.",
              "Founded CodeCrush, Inc.",
              "Founder and CEO of Miraigift, a General Incorporated Association",
              "In addition, he has been involved in establishing over 30 businesses, engaging in angel investment, contributing to philanthropic activities, and working to eliminate educational disparities while promoting world peace. He also places emphasis on management development.",
            ],
          },
        ],
        two: [
          {
            year: "In 1980",
            title: ["Joined The Bank of Tokyo-Mitsubishi UFJ, Ltd."],
          },
          {
            year: "In 2006",
            title: [
              "Appointed as Executive Officer of The Bank of Tokyo-Mitsubishi UFJ, Ltd",
            ],
          },
          {
            year: "In 2010",
            title: [
              "Appointed as Managing Executive Officer of The Bank of Tokyo-Mitsubishi UFJ, Ltd.",
            ],
          },
          {
            year: "In 2012",
            title: [
              "Appointed as Representative Director and Managing Director of The Bank of Tokyo-Mitsubishi UFJ, Ltd.",
            ],
          },
          {
            year: "In 2014",
            title: [
              "Appointed as Representative Director and Senior Managing Director of The Bank of Tokyo-Mitsubishi UFJ, Ltd.",
            ],
          },
          {
            year: "In 2015",
            title: [
              "Appointed as Full-time Corporate Auditor of Tokyu Corporation",
            ],
          },
          {
            year: "In 2020",
            title: [
              "Appointed as Chairman and Representative Director, Bankers Holding Corporation (currently Senior Advisor)",
            ],
          },
          {
            year: "In 2023",
            title: [
              "Appointed as Chairman of Co-Chert Corporation",
              "As Visiting Professor of Fujita Medical College",
              "As Auditor of Metaverse Promotion Council, Inc.",
              "As Director of Japan Performing Arts Foundation",
              "As Outside Director of Shinwa Wise HD",
              "Invested in numerous ventures and served as a board member and advisor. His life's work is to support the development of venture companies.",
            ],
          },
        ],
        ceo: "Representative Director",
        ceo_name: "Yudai Ishikawa",
        president: "Chairman of the Board",
        president_name: "Tadachiyo Osada",
      },
      //section 5
      footer: {
        info: [
          "・Asset-building for Owner-operator companies/High-net-worth/ High class",
          "・Finance/Inheritance/M&A/International Insurance Advisory ",
          "・Domestic and international real estate / subsidies / fundraising, etc.",
          "・Other advisory services",
        ],
        contact: "Contact",
        address: "4-28-10 Daita, Setagaya-ku, Tokyo",
      },
    },
  },
};
export default translation;
