import Styled from "./styled";
import { useTranslation } from "react-i18next";

const ContentCompanyProfile = () => {
  const { t } = useTranslation();
  return (
    <>
      <Styled>
        <div
          className="min-h-screen bg-blue-100 p-4 md:p-24 xl:p-32 text-center bg-cover flex justify-center"
          style={{
            backgroundImage: "url('bg-building.png')",
          }}
        >
          <div
            data-aos="fade-left"
            className="text-center flex flex-col"
          >
            <h1 className="text-[47px] md:text-5xl font-bold pb-[20px] text-[#FFFFFF]">
              {t("company_profile.title")}
            </h1>
            <div className="w-full lg:w-[594px] font-normal">
              <p
                style={{ color: "rgba(255, 255, 255, 0.8)" }}
                className="leading-[150%] text-md md:text-xl font-normal"
              >
                {t("company_profile.description")}
              </p>
            </div>
          </div>
        </div>
      </Styled>
    </>
  );
};
export default ContentCompanyProfile;
