import styled from "styled-components";
const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};
export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default styled.div`
  position: relative;
  .top-header {
    @media ${device.mobileS} {
      padding: 0px 20px 0px 20px;
      a {
        padding-left: 16px !important;
      }
    }
    @media ${device.laptop} {
      padding: 0px 54px 0px 46px;
      a {
        padding-left: 32px !important;
      }
    }
    height: 84px;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 54px 0px 46px;
    .active {
      color: #1cccbf;
      text-decoration: underline;
      text-underline-offset: 8px;
    }
    a {
      font-size: 16px;
      padding-left: 32px;

      color: #fff;
      line-height: 24px;
      img {
        padding-left: 4px;
      }
      &:first-child {
        padding-left: 0px;
        padding-right: 47px;
      }
    }
    .line {
      margin: 0px 16px;
      height: 30px;
      border: 0.5px solid rgba(255, 255, 255, 0.4);
    }
    .multile-language {
      cursor: pointer;
      padding: 12px 16px;
      gap: 4px;
      display: flex;
      align-items: center;
      width: 80px;
      justify-content: center;
      height: 48px;
      background: #636363;
      border-radius: 12px;

      img {
        padding-left: 4px;
      }
    }
    .input-container {
      position: relative;
      img {
        position: absolute;
        transform: translate(0, 50%);
        left: 16px;
        z-index: 999999;
      }
      input {
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.8);
        }
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        width: 360px;
        height: 48px;
        padding-left: 52px;
        &:focus {
          border: none !important;
          outline: 1px solid rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  .ant-anchor {
    background: transparent !important;
  }
  .ant-anchor-wrapper
    .ant-anchor
    .ant-anchor-link-active
    > .ant-anchor-link-title {
    color: #1cccbf;
  }
  .ant-anchor-wrapper-horizontal .ant-anchor .ant-anchor-ink {
    background-color: #1cccbf;
  }
`;
