import { Image } from "antd";
import { useEffect } from "react";
import useScrollspy from "../../../hooks/useScrollspy.js";
import ContentCompany from "../ContentCompany";
import ContentCompanyProfile from "../ContentCompanyProfile";
import { Dropdown } from "antd";
import ContentService from "../ContentService";
import FooterPage from "../FooterPage";
import Styled from "./styled";
import ContentProfile from "../ContentProfiles";
import "aos/dist/aos.css";
import AOS from "aos";
import i18next from "i18next";
import { LANGUAGE } from "../../../i18n/constant.js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "../../Sidebar/index.js";
import News from "../News.js";

const ContentPage = () => {
  const { t } = useTranslation();
  const ids = [
    t("menu.dashboard"),
    t("menu.business_content"),
    t("menu.company_profile"),
    t("menu.news"),
    t("menu.chairman"),
    t("menu.inquiry"),
  ];
  const [lng, setLng] = useState(localStorage?.getItem("lng") || LANGUAGE.JP);
  const activeId = useScrollspy(ids, 500);
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleChangeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    setLng(lng);
    localStorage.setItem("lng", lng);
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => handleChangeLanguage("jp")}
          className="flex items-center"
        >
          <img
            className="rounded-sm"
            style={{ width: 30 }}
            src="flag_jp.svg"
            alt="flag_jp"
          />
          <span className="ml-2 text-white font-bold">日本語</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => handleChangeLanguage("en")}
          className="flex items-center"
        >
          <img
            className="rounded-sm"
            style={{ width: 30 }}
            src="uk_flag.svg"
            alt="uk_jp"
          />
          <span className="ml-2 text-white font-bold">English</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Styled>
        {openSidebar && (
          <Sidebar setOpenSidebar={setOpenSidebar} activeId={activeId} />
        )}
        <div className="top-header">
          <div
            className={`p-1 rounded-lg lg:hidden ${openSidebar && "hidden"}`}
            style={{ background: "rgba(1,1,1,0.3)" }}
          >
            <img
              onClick={() => setOpenSidebar(true)}
              className={`w-[40px]`}
              src="burger_menu.svg"
              alt="burger_menu"
            />
          </div>

          <div className="flex items-center">
            <a className="max-lg:!px-0" href="#">
              <Image preview={false} src="logo-cocert.svg" />
            </a>
            {ids.map((menuItem, index) => (
              <a
                className={`hidden lg:block ${
                  menuItem === activeId && "active"
                }`}
                href={`#${menuItem}`}
              >
                {menuItem}
              </a>
            ))}
          </div>
          <div className="flex items-center">
            {/* <div className="input-container">
              <img alt="icon_search" src="icon_search.svg" />
              <input placeholder="Search" className="" />
            </div>
            <div className="line" /> */}
            <Dropdown
              overlayClassName="custom-dropdown"
              menu={{ items }}
              placement="bottom"
            >
              <div style={{ zIndex: 999999 }} className="multile-language">
                <img
                  className="w-[36px]"
                  src={lng === LANGUAGE.JP ? "flag_jp.svg" : "uk_flag.svg"}
                  alt="flag_jp"
                />
                <img src="caret_down_black.svg" alt="carret_down" />
              </div>
            </Dropdown>
          </div>
        </div>
        <div>
          <div id={t("menu.dashboard")} className="h-full text-center bg-white">
            <ContentCompany />
          </div>
          <div
            id={t("menu.business_content")}
            className="h-full text-center bg-white text-[#000]"
          >
            <ContentService />
          </div>
          <div
            id={t("menu.company_profile")}
            className="h-full text-center bg-white text-[#000]"
          >
            <ContentCompanyProfile />
          </div>
          <div
            id={t("menu.news")}
            className="h-full text-center bg-white text-[#000]"
          >
            <News />
          </div>
          <div id={t("menu.chairman")} className="h-full text-center bg-white ">
            <ContentProfile />
          </div>
          <div id={t("menu.inquiry")}>
            <FooterPage />
          </div>
        </div>
      </Styled>
    </>
  );
};
export default ContentPage;
