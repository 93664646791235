import styled from "styled-components";
export const Container = styled.div`
  .slick-arrow:before {
    display: none;
  }
`;
export default styled.div`
  .arrow {
    max-width: 80px !important;
  }
  a:hover {
    color: #004dff;
  }
  .ant-btn-default.ant-btn-background-ghost {
    border-color: #3e938c;
    color: #3e938c;
  }
`;
